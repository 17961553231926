<script>
export default {
  methods: {
    fileName(path) {
      const parts = path.split('/')
      return parts[parts.length - 1]
    },
    fileType(path) {
      const parts = path.split('.')
      return parts[parts.length - 1]
    },
    cmsFileUrl(path) {
      return [this.$env.CMS_URL, 'upload', path].join('/')
    },
  },
}
</script>
